<template>
  <v-icon v-if="!image || imageStatus === 'pending'" :icon="defaultIcon ?? 'image'" />
  <v-img v-else :src="image" :width="width" :height="height" alt="image" />
</template>

<script setup lang="ts">
const { organizationId, fileType, width, height } = defineProps<{
  organizationId: string
  fileType: "icon" | "logo"
  width?: string
  height?: string
  defaultIcon?: string
}>()

const organizationStore = useOrganizationStore()

const { data: image, status: imageStatus } = useLazyAsyncData(`organization-file:${organizationId}-${fileType}`, async () => {
  switch (fileType) {
    case "icon":
      return organizationStore.getIcon(organizationId)
    case "logo":
      return organizationStore.getLogo(organizationId)
  }
})
</script>
